import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deliverable-dropdowns"
export default class extends Controller {
  static targets = ["categorySelect", "typeSelect"]
  static values = {
    typesByCategory: Object
  }

  connect() {
    this.#populateTypes()
  }

  changeCategory() {
    this.typeSelectTarget.value = "" // Reset deliverable type to default value
    this.#populateTypes(true)
  }

  #populateTypes(reset = false) {
    const selectedCategory = this.categorySelectTarget.value
    let options = `<option value=''>Select type</option>`

    if (selectedCategory && this.typesByCategoryValue[selectedCategory]) {
      options += Object.entries(this.typesByCategoryValue[selectedCategory])
        .map(([key, val]) => `<option value="${key}">${val}</option>`)
        .join('')
    } else {
      options = `<option value=''>Select category first</option>`
    }

    this.typeSelectTarget.innerHTML = options

    if (!reset) {
      const initialValue = this.typeSelectTarget.dataset.initialValue || ''
      if (initialValue) {
        this.typeSelectTarget.value = initialValue
        delete this.typeSelectTarget.dataset.initialValue
      }
    } else {
      this.typeSelectTarget.value = "" // Reset to the first option, "Select type"
    }
  }
}
